import React from 'react';
import ArtistNavigation from './ArtistNavigation';

export const Layout = (props: { children: React.ReactElement | React.ReactFragment }): JSX.Element => {
  const { children } = props;
  return (
    <div className="min-h-full bg-page">
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="px-4 py-8 sm:px-0">{children}</div>
        </div>
      </main>
    </div>
  );
};

export const Page = (content: React.ReactElement | React.ReactFragment) => (
  <>
    <div className="lg:ml-48 pb-14 lg:pb-0">
      <Layout>{content}</Layout>
    </div>
    <ArtistNavigation />
  </>
);

export default Page;
