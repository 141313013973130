import React, { useState } from 'react';
import { InertiaLink, usePage } from '@inertiajs/inertia-react';
import useWindowSize from '../../hooks/useWindowSize';
import { Freshchat } from 'reactjs-freshchat';
import 'reactjs-freshchat/dist/index.css';

const IconButton = (props: {
  id: number;
  label: string;
  icon: JSX.Element;
  url?: string;
  selected?: boolean;
  notification?: boolean;
}): JSX.Element => {
  const { id, selected, notification, icon, label, url } = props;

  const commonStyles =
    'flex flex-col lg:flex-row items-center justify-center lg:justify-start lg:gap-x-2 p-4 lg:p-6 text-icon';
  if (selected)
    return (
      <div className={`${commonStyles} text-icon text-xs bg-page uppercase outline-0`}>
        {icon}
        {label}
        {notification && <span className="absolute ml-4 -mt-4 lg:mt-0 mb-4 rounded-full bg-orange-300 h-3 w-3" />}
      </div>
    );
  if (!url)
    // For testing only
    return (
      <div className={`${commonStyles} hover:bg-white text-xs uppercase focus:bg-white outline-0 hover:cursor-pointer`}>
        {icon}
        {label}
        {notification && <span className="absolute ml-4 -mt-4 lg:mt-0 mb-4 rounded-full bg-orange-300 h-3 w-3" />}
      </div>
    );
  return (
    <InertiaLink
      href={url}
      tabIndex={id}
      className={`${commonStyles} hover:bg-white text-xs uppercase focus:bg-white outline-0`}
    >
      {icon}
      {label}
      {notification && <span className="absolute ml-4 -mt-4 lg:mt-0 mb-4 rounded-full bg-orange-300 h-3 w-3" />}
    </InertiaLink>
  );
};

export const Navigation = (navProps: {
  buttons: {
    icon: JSX.Element;
    label: string;
    url?: string;
    selected?: boolean;
    notification?: boolean;
  }[];
  selected?: string;
}): JSX.Element => {
  const { buttons, selected, ...restProps } = navProps;
  const [selectedItem, setSelectedItem] = useState(selected);
  const { props } = usePage<InertiaPage>();
  const { user, chat_token } = props;
  const { width } = useWindowSize();

  return (
    <>
      <nav
        {...restProps}
        className="fixed bottom-0 lg:top-0 w-full lg:w-48 flex lg:flex-col justify-evenly lg:justify-start text-base font-medium text-icon bg-navigation border-t-2 lg:border-none border-primary"
      >
        {buttons.map((button, index) => (
          <div
            role="button"
            key={`b-${index}`}
            onClick={() => setSelectedItem(button.url)}
            onKeyDown={() => setSelectedItem(button.url)}
            tabIndex={index}
            className="w-full"
          >
            <IconButton {...button} id={index} selected={!!button.url && selectedItem === button.url} />
          </div>
        ))}
      </nav>
      <div className="chat fixed right-0 lg:right-auto lg:left-10 bottom-16 lg:bottom-0 ">
        <Freshchat
          token={chat_token}
          host={'https://wchat.eu.freshchat.com'}
          externalId={user.id}
          firstName={user.frontname}
          lastName={user.lastname}
          label={width < 640 ? 'Chat' : 'Chat with us'}
          ic_styles={{
            backgroundColor: '#4C4C4C',
            color: '#fff',
          }}
        />
      </div>
    </>
  );
};
