import React from 'react';
import { usePage } from '@inertiajs/inertia-react';
import { Navigation } from './Navigation';
import { IconArtworks, IconHome, IconSales, IconShipping } from '../Icons';

export const ArtistNavigation = () => {
  const { props } = usePage<InertiaPage>();
  const buttons = [
    {
      icon: <IconHome />,
      label: 'Home',
      url: '/home',
    },
    {
      icon: <IconSales />,
      label: 'Sales',
      url: '/sales',
      notification: !!props.notifications?.sales,
    },
    {
      icon: <IconShipping />,
      label: 'Shipments',
      url: '/shipments',
      notification: !!props.notifications?.shipments,
    },
    {
      icon: <IconArtworks />,
      label: 'Artworks',
      url: '/artworks',
      notification: !!props.notifications?.artworks,
    },
  ];
  return <Navigation buttons={buttons} selected={window.location.pathname} />;
};

export default ArtistNavigation;
